.asset-map-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .asset-map-controls {
    margin-bottom: 20px;
    z-index: 10;
    position: relative;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .asset-type-dropdown {
    margin-bottom: 10px;
  }
  
  .select-all-button {
    padding: 10px 15px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  .custom-marker {
    cursor: pointer;
    border: 2px solid rgb(193, 193, 193);
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }
  .select-all-button:hover {
    background-color: #2980b9;
  }
  .create-geofence-button {
    padding: 10px 15px;
    background-color: #14c100;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .create-geofence-button:hover {
    background-color: #2fe91e;
  }


  .leaflet-container {
    flex-grow: 1;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .leaflet-popup-content-wrapper {
    background-color: white;
    color: #333;
    border-radius: 5px;
    padding: 10px;
  }
  
  .leaflet-popup-content h3 {
    margin-top: 0;
    color: #2c3e50;
  }
  
  .leaflet-popup-content p {
    margin: 5px 0;
  }
  
  .leaflet-popup-content ul {
    padding-left: 20px;
  }
  
  .leaflet-popup-content li {
    margin-bottom: 5px;
  }
  
  /* React-select custom styles */
  .react-select__control {
    border-color: #ccc !important;
  }
  
  .react-select__control:hover {
    border-color: #aaa !important;
  }
  
  .react-select__control--is-focused {
    border-color: #3498db !important;
    box-shadow: 0 0 0 1px #3498db !important;
  }
  
  .react-select__menu {
    z-index: 1001 !important;
  }