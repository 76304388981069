.edit-button {
  padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }

  .edit-button:hover {
    background-color: #2980b9;
  }

  .delete-button {
    padding: 5px 10px;
      background-color: #f98d00;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;
    }
  
    .delete-button:hover {
      background-color: #f73408;
    }