.observation-type-management {
    display: flex;
    gap: 20px;
  }
  
  .template-list {
    flex: 1;
    max-width: 300px;
  }
  
  .observation-type-form {
    flex: 2;
  }
  
  .template-item {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
  }
  
  .template-item:hover {
    background-color: #e0e0e0;
  }

  .template-category {
    margin-bottom: 10px;
  }
  
  .category-header {
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .category-header:hover {
    background-color: #e0e0e0;
  }
  
  .expand-icon {
    font-size: 12px;
  }
  
  .category-templates {
    margin-left: 20px;
  }
  
