/* ObservationListPage.css */
.observations-list-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    color: var(--primary-color);
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .observation-type-selector {
    margin-bottom: 20px;
    width: 25%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: white;
    font-size: 16px;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
  }
  
 
  
  .observation-type-selector select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  .edit-controls {
    margin-bottom: 20px;
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .edit-button {
    background-color: var(--primary-color);
    color: white;
  }
  
  .edit-button:hover {
    opacity: 0.9;
  }
  
  .action-button {
    padding: 5px 10px;
    margin-right: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #e74c3c;
  }
  
  .loading {
    font-size: 18px;
    color: #7f8c8d;
  }
  
  .column-visibility-filter {
    margin-bottom: 20px;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .column-visibility-filter h4 {
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .column-visibility-filter label {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;
  }
  
  .column-visibility-filter input {
    margin-right: 5px;
  }