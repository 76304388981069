
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px; 
  width: auto;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-controls {
  display: flex;
  align-items: center;
}

.navbar-item {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  position: relative;
}

.navbar-item:hover {
  background-color: rgba(255,255,255,0.1);
}

.has-dropdown {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  color: black;
  min-width: 200px;
  border: 1px solid #ddd;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  padding: 10px 15px;
  color: black;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: rgba(0,0,0,0.1);
}

.user-email {
  margin-right: 15px;
}

.btn-logout {
  background-color: rgba(255,255,255,0.2);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-logout:hover {
  background-color: rgba(255,255,255,0.3);
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-x: hidden;
}

.navbar-mobile-menu,
.navbar-mobile-menu-button {
  display: none;
}

.navbar .navbar-mobile-menu-button {
  display: none;
}


@media (max-width: 768px) {
  .navbar-content {
    flex-wrap: wrap;
  }

  .navbar-menu {
    display: none;
  }

  .navbar .navbar-mobile-menu-button {
    display: block;
  }

  .navbar-mobile-menu {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .navbar-mobile-menu.active {
    display: flex;
  }

  .navbar-controls {
    flex-grow: 1;
    justify-content: flex-end;
  }

  .navbar-item {
    width: 100%;
    padding: 15px 10px;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    border: none;
    background-color: rgba(255,255,255,0.1);
  }

  .dropdown-item {
    color: white;
    padding: 10px 20px;
  }

  .btn-logout {
    display: none;
  }

  .btn-logout.mobile {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .main-content {
    padding: 10px;
  }

  .user-email {
    display: none;
  }
}