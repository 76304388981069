.asset-type-management {
    display: flex;
    gap: 20px;
  }
  
  .template-list {
    flex: 1;
    max-width: 300px;
  }
  
  .asset-type-form {
    flex: 2;
  }
  
  .MuiTreeItem-content {
    padding: 5px 0;
  }
  
  .MuiTreeItem-label {
    font-size: 14px;
  }
  
  .MuiTreeItem-group {
    margin-left: 17px;
    padding-left: 18px;
    border-left: 1px dashed rgba(0, 0, 0, 0.4);
  }