/* AssetListPage.css */
.asset-list-container {
    padding: 20px;
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .asset-type-selector {
    margin-bottom: 20px;
  }
  
  .asset-type-selector select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .edit-controls {
    margin-bottom: 20px;
  }
  
  
 .add-button {
    padding: 10px 15px;
    font-size: 14px;
    min-width: 100px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .add-button {
    background-color: #2ecc71;
    color: white;
  }
  
  .asset-grid {
    margin-bottom: 20px;
  }
  
  .csv-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f39c12;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .loading {
    font-size: 18px;
    color: #7f8c8d;
  }
  .column-visibility-filter {
    margin-bottom: 20px;
  }
  
  .column-visibility-filter label {
    display: inline-block;
    margin-right: 15px;
  }
  
  .column-visibility-filter input {
    margin-right: 5px;
  }

  /* AssetListPage.css */
.ag-theme-alpine .ag-cell-inline-editing {
  height: 30px;
}

.action-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #2980b9;
}
.asset-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.asset-link:hover {
  color: #0056b3;
}

.delete-button {
  background-color: #e74c3c;
}

.delete-button:hover {
  background-color: #c0392b;
}

.observation-button {
  background-color: #2ecc71;
}

.observation-button:hover {
  background-color: #27ae60;
}