.asset-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
  }
  
  .asset-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .asset-modal h2 {
    margin-top: 0;
  }
  
  .asset-modal button {
    margin-top: 20px;
  }

  .photo-carousel {
    margin: 20px 0;
    text-align: center;
  }
  
  .photo-carousel img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
  
  .carousel-controls {
    margin-top: 10px;
  }
  
  .carousel-controls button {
    margin: 0 10px;
  }
  
  .photo-upload {
    margin: 20px 0;
  }
  .asset-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .asset-modal h2 {
    color: #3498db;
    margin-bottom: 20px;
  }
  
  .asset-modal p {
    margin-bottom: 10px;
  }
  
  .asset-modal strong {
    font-weight: bold;
    color: #2c3e50;
  }
  
  .photo-carousel {
    margin: 20px 0;
    text-align: center;
  }
  
  .photo-carousel img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
  
  .carousel-controls {
    margin-top: 10px;
  }
  
  .carousel-controls button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .photo-upload {
    margin: 20px 0;
  }
  
  .photo-upload input[type="file"] {
    display: none;
  }
  
  .photo-upload label {
    padding: 10px 15px;
    background-color: #2ecc71;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .document-upload {
    margin: 20px 0;
  }
  
  .document-list {
    margin-top: 20px;
  }
  
  .document-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .document-list li {
    margin-bottom: 10px;
  }
  
  .document-list button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  #map {
    touch-action: none;
  }