.language-selector {
    position: relative;
    display: inline-block;
  }
  
  .language-dropdown-toggle {
    background-color: #3498db;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .dropdown-arrow {
    margin-left: 8px;
    font-size: 12px;
  }
  
  .language-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .language-dropdown-menu li {
    padding: 0;
  }
  
  .language-dropdown-menu button {
    width: 100%;
    padding: 12px 16px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    transition: background-color 0.2s;
  }
  
  .language-dropdown-menu button:hover {
    background-color: #e9e9e9;
  }