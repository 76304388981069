.billing-container {
    max-width: 1000px; /* Increased from 800px */
    margin: 0 auto;
    padding: 20px;
  }
  
  .billing-container h1 {
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  .subscription-plans {
    display: flex;
    flex-wrap: wrap;
    gap: 30px; /* Increased from 20px to 30px */
    margin-bottom: 40px;
  }
  
  .plan-container {
    flex: 1 1 calc(33.333% - 40px); /* Increased the subtraction from 30px to 40px */
    display: flex;
    min-height: 300px; /* Set a minimum height for consistency */
  }
  
  @media (max-width: 1024px) {
    .plan-container {
      flex: 1 1 calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .plan-container {
      flex: 1 1 100%;
    }
  }

  .plan-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 100%; /* Ensure all cards have the same height */
    width: 100%;
  }
  
  
  .plan-card h2 {
    color: var(--primary-color);
  }
  
  .plan-card p {
    margin-bottom: 10px;
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: white;
  }
  
  .btn-secondary {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .btn-warning {
    background-color: #f39c12;
    color: white;
  }
  
  .btn:hover:not(:disabled) {
    opacity: 0.9;
  }
  
  .error {
    color: red;
    margin-top: 20px;
  }

  .billing-form {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .billing-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .billing-form input,
  .billing-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 16px;
  }
  
  .billing-form button {
    padding: 10px 15px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .billing-form button:hover {
    opacity: 0.9;
  }
  
  .billing-info {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
  }
  
  .billing-info h2 {
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .billing-info p {
    margin-bottom: 10px;
  }
  .subscription-item {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .subscription-item h3 {
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .subscription-item p {
    margin-bottom: 5px;
  }
  
  .subscription-actions {
    margin-top: 15px;
  }
  
  .billing-actions {
    margin-top: 70px;
    text-align: left; /* Center the button */
  }
  .billing-actions .btn-secondary {
    padding: 12px 24px; /* Larger padding */
    font-size: 16px; /* Larger font size */
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: white;
  }
  
  .btn-secondary {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .btn-warning {
    background-color: #f39c12;
    color: white;
  }
  
  .btn:hover:not(:disabled) {
    opacity: 0.9;
  }
  .active-plan {
    border: 2px solid var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .plan-card-button {
    margin-top: auto; /* Push the button to the bottom */
    padding-top: 15px; /* Add some space above the button */
    display: flex;
    justify-content: center; /* Center the button horizontally */
    width: 100%; /* Ensure the container spans the full width of the card */
  }
  .plan-card-button .btn {
    min-width: 200px; /* Set a minimum width for all buttons */
    text-align: center; /* Ensure text is centered within the button */
  }
  .plan-card-content {
    min-height: 200px; /* Adjust this value as needed */
  }
  .plan-card-content {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .plan-card h2 {
    color: var(--primary-color);
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 1.5em;
    font-weight: bold;
    color: #2ecc71;
    margin-bottom: 15px;
  }
  
  .plan-description, .plan-overuse {
    margin-bottom: 10px;
  }
  
  .highlight {
    color: #3498db;
    font-weight: bold;
  }
  
  .active-plan .highlight {
    color: #20b318;
  }
  
  .plan-card p {
    margin-bottom: 8px;
  }
  
  .active-plan {
    border: 2px solid var(--primary-color);
    box-shadow: 0 0 15px rgba(52, 152, 219, 0.3);
  }
  
  .btn-warning {
    background-color: #e74c3c;
    color: white;
  }
  
  .btn-success {
    background-color: #2ecc71;
    color: white;
  }
  .billing-container h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .plan-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .plan-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .plan-price {
    font-size: 2em;
    font-weight: bold;
    color: #2ecc71;
    margin-bottom: 15px;
  }
  
  .per-month {
    font-size: 0.5em;
    color: #7f8c8d;
    vertical-align: super;
  }
  
  .plan-description, .plan-overuse {
    margin-bottom: 15px;
  }
  
  .highlight {
    color: #3498db;
    font-weight: bold;
  }
  
  .btn-primary {
    background-color: #3498db;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover:not(:disabled) {
    background-color: #2980b9;
  }
  
  .btn-primary:disabled {
    background-color: #bdc3c7;
  }
  
  .error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    text-align: center;
  }
  
  .loading {
    text-align: center;
    font-size: 1.2em;
    margin-top: 50px;
  }